import img1 from '../images/aboutus.svg'
import img2 from '../images/mobile.svg'

export const ObjectOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Services',
    headline: 'Web and mobile development',
    descriptions:'ExactRes implementation Team Experts bring together development expertise, and a deep understanding of the way people work. By involving our customers in a highly iterative deployment approach powered by new technology, and with access to unlimited resources through crowdsourcing, we deliver results and solutions for bussines for the better imporvement',
    buttonLabel: 'Get started',
    imgStart: false,
    img: img1,
    alt: 'about us',
    dark: true,
    primary: true,
    darkText: false,
}

export const ObjectTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    descriptions:'tesing tesing testing tesing Unlimited Transactions with zero fees',
    buttonLabel: 'Get started',
    imgStart: true,
    img: img2,
    alt: 'discover',
    dark: false,
    primary: false,
    darkText: true,
}
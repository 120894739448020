import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper,ServicesCard,
ServicesIcon, ServicesH2, ServicesP } from './ServiceStyle'
import Icon1 from '../../images/solutions.svg'
import Icon2 from '../../images/website.svg'
import Icon3 from '../../images/mobile.svg'

const Services = () => {
  return (
    <>
       <ServicesContainer id='services'>
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>Solutions</ServicesH2>
                <ServicesP>We offer business solutions through the development of tailored programs designed to enhance and optimize various aspects of their operations</ServicesP>
            </ServicesCard>
             <ServicesCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Restaurant Menus</ServicesH2>
                <ServicesP>Enhancing the professional image of restaurants by offering businesses the capability to implement QR codes and digital menus.</ServicesP>
            </ServicesCard>
             <ServicesCard>
                <ServicesIcon src={Icon3}/>
                <ServicesH2>Mobile Application</ServicesH2>
                <ServicesP>Facilitating businesses with the capability to customize a mobile app for their needs on both Android and iOS platforms.</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
       </ServicesContainer> 
    </>
  )
}

export default Services
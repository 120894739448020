import React, { useState } from 'react'
import {HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1 ,HeroP ,HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroSectionStyle'
import Video from '../../videos/video.mp4'
import {Button} from '../ButtonElement'

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer id='home'>
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
        </HeroBg>
        <HeroContent>
            <HeroH1>Exactres is the solution for your business </HeroH1>
            <HeroP>Our services provide solutions for any business by developing programs which will help improve their business</HeroP>
            <HeroBtnWrapper>
                <Button 
                    to="about" 
                    onMouseEnter={onHover} 
                    onMouseLeave={onHover}
                    primary='true'
                    dark='true'
                >
                    Get Started {hover ? <ArrowForward/> : <ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
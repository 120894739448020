import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  ContactFormContainer,
  ContactFormWrapper,
  ContactForm,
  ContactFormH2,
  ContactFormInput,
  ContactFormTextArea,
  ContactFormError,
  ContactFormBtn,
  ContactFormH3,
  ContactFormSuccess,
  ContactFormH1,
  SocialIconLink,
  SocialIconPhone,
  SocialMediaContainer
} from "./ContactStyle";
import img from "../../images/contact.svg";
import { FaWhatsapp, FaPhone } from "react-icons/fa";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
    console.log(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g31o55j",
        "template_lguzenm",
        form.current,
        "A9Q6AQOlCyV0EAKg1"
      )
      .then(
        (result) => {
          // console.log(result.text);
          setSuccess(true);
          setEmail("");
          setMessage("");
          setName("");
        },
        (error) => {
          setError(true);
          // console.log(error.text);
        }
      );
  };

  return (
    <>
      <InfoContainer id="contact">
        {/* <InfoWrapper> */}
        {/* <InfoRow> */}
        {/* <Column1> */}
        {/* <ContactFormContainer> */}
        <ContactFormWrapper>
          <ContactForm ref={form} onSubmit={sendEmail}>
            <ContactFormH2>Contact Us</ContactFormH2>
            <SocialMediaContainer>
              <SocialIconLink href="http://wa.me/+972585752483" target="_blank">
                <FaWhatsapp />
              </SocialIconLink>
              <SocialIconPhone href="" target="_blank">
                <FaPhone />
                <ContactFormH1>0585752483</ContactFormH1>
              </SocialIconPhone>
            </SocialMediaContainer>
            <ContactFormH3>Name</ContactFormH3>
            <ContactFormInput
              value={name}
              type="text"
              name="name"
              onChange={handleNameChange}
            />
            <ContactFormH3>Email</ContactFormH3>
            <ContactFormInput
              value={email}
              type="email"
              name="email"
              onChange={handleEmailChange}
            />
            <ContactFormH3>Message</ContactFormH3>
            <ContactFormTextArea
              value={message}
              name="message"
              onChange={handleMessageChange}
            />
            {error ? (
              <ContactFormError>
                Message not sent, Check your details!
              </ContactFormError>
            ) : (
              <></>
            )}
            {success ? (
              <ContactFormSuccess>
                Your message have been sent!
              </ContactFormSuccess>
            ) : (
              <></>
            )}
            <ContactFormBtn type="submit">Send message</ContactFormBtn>
          </ContactForm>
        </ContactFormWrapper>
        {/* </ContactFormContainer> */}
        {/* </Column1> */}
        {/* <Column2>
                        <ImgWrap>
                            <Img src={img} alt={'contact us'}/>
                        </ImgWrap> 
                      
                    </Column2> */}
        {/* </InfoRow> */}
        {/* </InfoWrapper> */}
      </InfoContainer>
    </>
  );
};

export default Contact;

import React, { useState } from 'react'
import Contact from '../components/Contact';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import NavBar from '../components/Navbar'
import Services from '../components/Services';
import SideBar from '../components/Sidebar'
import { ObjectOne, ObjectTwo } from '../Data/Data';
import Footer from '../Footer';

const Home = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
        <SideBar isOpen={isOpen} toggle={toggle}/>
        <NavBar toggle={toggle}/> 
        <HeroSection/>
        <InfoSection {...ObjectOne}/>
        {/* <InfoSection {...ObjectTwo}/> */}
        <Services/>
        <Contact {...ObjectOne}/>
        <Footer/>
    </>
  )
}

export default Home
import styled from 'styled-components'

export const InfoContainer = styled.div`
    height: 800px;
    /* width: 500px; */
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #d9dadb;

    @media screen and (max-width: 768px){
        padding: 100px 0;
    }
`

export const InfoWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: 800px;
    width: 100%;
    /* max-width: 1100px; */
    margin-left: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const InfoRow = styled.div`
    display: flex;
    /* grid-auto-columns: minmax(auto, 1fr); */
    align-items: center;
    /* grid-template-areas: 'col1'; */

    @media screen and (max-width: 768px){
        /* grid-template-areas:'col1'; */

    }
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const ContactFormContainer = styled.div`
    max-width: 940px;
    padding-top: 0;
    padding-bottom: 60px;

`

export const ContactFormWrapper = styled.div`
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 70px 50px;
`

export const ContactForm = styled.form`
    /* width: 100%;
    max-width: auto;
    padding: 40px;
    background-color: #7d8496;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2); */
    background: #dadfeb; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 550px;
    max-height: 1080px;
    padding: 30px;//15px
    box-shadow: 1px 1px 3px 3px rgba(3, 78, 209, 0.8);
    transition: all 0.2s ease-in-out;
`

export const SocialMediaContainer = styled.div`
    padding: 10px;
`

export const ContactFormH2 = styled.h2`
    font-size: 22px;
    color: #1184f0;
    padding-bottom: 10px;
    font-weight: bold;
    font-family: Georgia;
`

export const ContactFormInput = styled.input`
    display: block;
    width: 100%;
    background-color: #eee;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin: 10px 0 20px 0;
    padding: 20px;
    box-sizing: border-box;
    border-color:#000000;
`

export const ContactFormTextArea = styled.textarea`
    background-color: #eee;
    width: 100%;
    min-height: 100px;
    resize: none;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin: 10px 0 20px 0;
    padding: 20px;
    box-sizing: border-box;
    border-color:#000000;
`

export const ContactFormBtn = styled.button`
    display: block;
    background-color: #1184f0;
    color: #fff;
    font-size: 1rem;
    border: 0;
    border-radius: 5px;
    height: 45px;
    padding: 0 20px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover{
        background-color: #000;
        transition: 0.3s ease-out;
    }
`
export const ContactFormH1 = styled.h1`
    color: #000;
    font-size: 18px;
    display: inline;
    padding-left: 10px;
`

export const ContactFormH3 = styled.h3`
    font-size: 16px;
    color: #000;
    padding-top: 5px;
`

export const ContactFormError = styled.div`
    color: red;
    font-weight: 800;
    margin: 0 0 40px 0;
`

export const ContactFormSuccess = styled.div`
    color: green;
    font-weight: 800;
    margin: 0 0 40px 0;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
    background-color: #fff;
    border-radius: 30%;
`
export const Img = styled.img`
    width:100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`

export const SocialIconLink = styled.a`
    color: green;
    font-size: 32px;
    padding-left: 10px;
    
     &:hover{
        color: #000000;
        transition: 0.3s ease-out;
    }
`

export const SocialIconPhone = styled.span`
    color: #000;
    font-size: 30px;
    padding-left: 10px;
    text-decoration: none;
     &:hover{
        color: #1184f0;
        transition: 0.3s ease-out;
    }
`
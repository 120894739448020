import React, { useEffect, useState } from 'react'
import { Nav, NavBarContainer, NavLogo, MobileIcon,
    NavMenu,NavItem, NavLinks, NavBtn, NavBtnLink, LogoImg } from './NavBarStyle'
import {FaBars} from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import logo from '../../images/logo.png'

const NavBar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    },[])

    const toggleHome = () =>{
        scroll.scrollToTop();
    }

  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavBarContainer>
                <NavLogo to="/" onClick={toggleHome}>
                    <LogoImg src={logo}/>
                </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks 
                            to="home" 
                            smooth={true} 
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-80}

                        >
                        Home</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                            to="about"
                            smooth={true} 
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-60}
                        >
                        About Us</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks 
                            to="services"
                            smooth={true} 
                            duration={500}
                            spy={true}
                            exact='true'
                            offset={-60}
                            >
                            Services</NavLinks>
                    </NavItem>
                </NavMenu>
                <NavBtn>
                    <NavBtnLink 
                        to="contact"
                        smooth={true} 
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-60}
                    >
                    Contact Us</NavBtnLink>
                </NavBtn>
            </NavBarContainer>
        </Nav>
    </>
  )
}

export default NavBar
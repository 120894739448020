import React from 'react'
import { SideBarContainer, Icon, CloseIcon, SideBarWrapper, SideBarMenu, SideBarLink,
    SideBtnWrap, SidebarRoute
} from './SideBarStyle'
const SideBar = ({isOpen, toggle}) => {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SideBarWrapper>
            <SideBarMenu>
                <SideBarLink to="home" onClick={toggle}>Home</SideBarLink>
                <SideBarLink to="about" onClick={toggle}>About Us</SideBarLink>
                <SideBarLink to="services" onClick={toggle}>services</SideBarLink>
                {/* <SideBarLink to="signup" onClick={toggle}>signup</SideBarLink> */}
            </SideBarMenu>
            <SideBtnWrap>
                <SidebarRoute to="contact" onClick={toggle}>Contact Us</SidebarRoute>
            </SideBtnWrap>
        </SideBarWrapper>
    </SideBarContainer>
  )
}

export default SideBar
import React from 'react'
import {Button2} from '../ButtonElement'
import { InfoContainer, InfoWrapper,InfoRow ,Column1,Column2,TextWrapper,TopLine,Heading,
     ImgWrap,SubTitle,BtnWrap, Img } from './InfoStyle'
import img1 from '../../images/aboutus.svg'
const InfoSection = ({lightBg,id, imgStart, topLine, lightText,
        headline, darkText, descriptions, buttonLabel, img, alt,
        primary, dark, dark2}) => {
  return (
    <>
        <InfoContainer id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <SubTitle darkText={darkText}>{descriptions}</SubTitle>
                            {/* <BtnWrap>
                                <Button2 
                                    to="home"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark={dark ? 1 : 0}
                                    dark2={dark2 ? 1 : 0}
                                >{buttonLabel}</Button2>
                            </BtnWrap> */}
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default InfoSection